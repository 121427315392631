import React, { useEffect, useState } from "react";

import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";

import { ReactComponent as BellIcon } from "../icons/bell.line.svg";
import { ReactComponent as BellFillIcon } from "../icons/bell.svg";
import { ReactComponent as CardFillIcon } from "../icons/card.fill.svg";
import { ReactComponent as CardIcon } from "../icons/card.svg";
import { ReactComponent as CartFillIcon } from "../icons/cart.fill.svg";
import { ReactComponent as CartIcon } from "../icons/cart.svg";
import { ReactComponent as FeedFillIcon } from "../icons/feed.fill.svg";
import { ReactComponent as FeedIcon } from "../icons/feed.svg";
import { ReactComponent as MatchingFillIcon } from "../icons/matching.fill.svg";
import { ReactComponent as MatchingIcon } from "../icons/matching.svg";

import { ReactComponent as ProfileFillIcon } from "../icons/profile.fill.svg";
import { ReactComponent as ProfileIcon } from "../icons/profile.line.svg";

import { t } from "i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useUserQuery from "../components/api/queries/useUserQuery";
import { queryGet } from "../utils/queryClient";

const ENABLED_COLOR = "#5C3DFA"
const DISABLED_COLOR = "#888888"

const NotificationBell = ({ count, isSelected, fill }) => {
  const displayCount = count > 99 ? '99+' : count;
  const newCountTextStyle ={
    backgroundColor: "#FF2E37",
    color: "white",
    fontSize: "10px",
  }

  return (
    <Box position="relative" display="inline-block" zIndex={1}>
      {
        isSelected
          ? <BellFillIcon fill={fill} />
          : <BellIcon width={"24px"} height={"24px"} fill={fill} />
      }
      {count > 0 && (
        <Box
          className="absolute right-0 top-0"
          px="4px"
          borderRadius="full"
          style={newCountTextStyle}
        >
          {displayCount}
        </Box>
      )}
    </Box>
  );
};


export default function HomeBottomNav({tabIndex}) {
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex || 0);

  const user = useUserQuery();

  const isKorean = () => {
    return user && user.country === "KR"
  }

  // #region 노티 카운트 가져오기
  const { data: unreadMessages } = useQuery(
    ["unreadMessages"],
    () => queryGet(`/notifications/unread_messages`),
    {
      cachedTime: 2500,
      staleTime: 10000,
    },
  );
  const count = unreadMessages?.unreadMessagesCount ?? 0;
  // #endregion

  useEffect(()=>{
    setSelectedTabIndex(tabIndex)
  }, [tabIndex]);

  const setEnabledColor = (isEnabled) => {
    return isEnabled ? ENABLED_COLOR : DISABLED_COLOR
  }

  const TABS = [
    {index: 0, inActiveIcon: <CardIcon fill={DISABLED_COLOR} width={24} height={24}/>, activeIcon: <CardFillIcon fill={ENABLED_COLOR} width={24} height={24}/>, name: t("tabs.collections")},
    {index: 1, inActiveIcon: <MatchingIcon fill={DISABLED_COLOR} count={count} width={24} height={24}/>, activeIcon: <MatchingFillIcon count={count} fill={ENABLED_COLOR} width={24} height={24}/>, name: t("tabs.tradingPhotoCard")},
    {index: 2, inActiveIcon: <FeedIcon fill={DISABLED_COLOR} width={24} height={24}/>, activeIcon: <FeedFillIcon fill={ENABLED_COLOR} width={24} height={24}/>, name: t("tabs.feed")},
    {index: 3, inActiveIcon: <CartIcon fill={DISABLED_COLOR} width={24} height={24}/>, activeIcon: <CartFillIcon fill={ENABLED_COLOR} width={24} height={24}/>, name: t("tabs.store")},
    {index: 4, inActiveIcon: <ProfileIcon fill={DISABLED_COLOR} width={24} height={24}/>, activeIcon: <ProfileFillIcon fill={ENABLED_COLOR} width={24} height={24}/>, name: t("tabs.profile")}
  ]

  const onClickTabButton = (index) => {
    setSelectedTabIndex(index);
    if(selectedTabIndex === index) {
      return window.scrollTo({top: 0, behavior: "smooth"});
    }
    switch(index){
      case 0:
        return navigate(`/home`)
      case 1:
        return navigate(`/tradings/photo_cards`)
      case 2:
        return navigate(`/feed`)
      case 3: {
        const baseUrl = isKorean()
          ? 'https://store.pocapan.com'
          : 'https://storeglobal.pocapan.com'

        return window.location.href = `${baseUrl}/?pocapan_user_id=${user?.userId}`
      }
      case 4:
        return navigate(`/my`)
      default:
        return console.log("Nothing. Stay current page");
    }
  }

  return(
    <Box
      maxW={"xl"}
      className="bottomNavbar safeAreaPaddingBottom"
      style={{filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))"}}
    >
      <Grid
        backgroundColor={"#FFF"}
        templateColumns={`repeat(${TABS.length}, 1fr)`}
        alignItems={"center"}
        paddingStart="10px"
        paddingEnd="10px"
      >
        {
          TABS.map((tab) => {
            return (
              <GridItem key={tab.index} paddingTop={"13px"} paddingBottom={"10px"} colSpan={1} onClick={() => onClickTabButton(tab.index)} _hover={{background: "#FFF"}} style={{WebkitTapHighlightColor: "rgba(0,0,0,0)", outline: "none"}}>
                <Stack align='stretch' alignItems={"center"} spacing={"2px"}>
                  {selectedTabIndex === tab.index ? tab.activeIcon : tab.inActiveIcon}
                  <Text
                    fontSize={"11px"}
                    fontWeight={500}
                    color={setEnabledColor(selectedTabIndex === tab.index)}
                  >
                    {tab.name}
                  </Text>
                </Stack>
              </GridItem>
            )
          })
        }
      </Grid>
    </Box>
  )
}
