import { Image } from "@chakra-ui/react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import styled from 'styled-components';
import LightColors from '../../constants/LightColors';
import { t } from "i18next";

const GradientBorderBox = styled.div`
  width: 100%;
  background:
    linear-gradient(99.67deg, #5C3DFA 0%, #00C8FF 50%, #5500FF 100%) border-box,
    linear-gradient(white, white) padding-box;
  border: 1.5px solid transparent;
  border-radius: 12px;
  box-shadow:
    0px 0px 14px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.01);
  padding: 1px;
  overflow: auto;
`

const ContentContainer =  styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px 0px;
`
const HeaderWrapper = styled.div`
  padding: 0px 20px 12px 20px;
  background: white;
  border-radius: 12px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: ${LightColors.textmainlv2};
`

const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: ${LightColors.textmainlv2};
`


const ViewAllButton = styled.button`
  color: ${LightColors.textprimary};
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

const ScrollContainer = styled.div`
  width: 100%;
  scroll-snap-align: start;

  .react-horizontal-scrolling-menu--scroll-container {
    padding-left: 20px;
  }
`

export default function PendingPhotoCardsSection({ onViewAllClick, onPhotoCardClick, reportedPhotoCards }) {
  return (
    <GradientBorderBox>
      <ContentContainer>
        <HeaderWrapper>
          <Header>
            <div className="flex flex-col gap-1">
              <Title>{t("PendingPhotoCardsSection.title")}</Title>
              <Description>{t("PendingPhotoCardsSection.description")}</Description>
            </div>
            {
              onViewAllClick &&
              <ViewAllButton onClick={onViewAllClick}>{t("PendingPhotoCardsSection.viewAll")}</ViewAllButton>
            }
          </Header>
        </HeaderWrapper>

        <ScrollContainer>
          <ScrollMenu separatorClassName="pl-2">
            {reportedPhotoCards?.map(photoCard => (
              <Image
                key={photoCard.photoCardId}
                src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                height={"90px"}
                maxWidth={"57px"}
                objectFit={"cover"}
                aspectRatio={"55/87"}
                loading={"lazy"}
                borderRadius="8px"
                boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(0, 0, 0, 0.08)"}
                onClick={() => onPhotoCardClick(photoCard)}
              />
            ))}
          </ScrollMenu>
        </ScrollContainer>
      </ContentContainer>
    </GradientBorderBox>
  )
}


