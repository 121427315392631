import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useGroupTradingPhotoCardsQuery(groupId) {
  const { data: tradingPhotoCard } = useQuery(
    ["GroupTradingPhotoCards", groupId],
    () => queryGet(`/api/v2/trading_photo_cards/groups/${groupId}`),{
      select: (data) => data.tradingPhotoCards,
      enabled: !!groupId
    }
  );
  return tradingPhotoCard;
}
