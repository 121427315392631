import { AbsoluteCenter, Center } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import RegularSolidButton from '../../components/buttons/RegularSolidButton';
import LightColors from '../../constants/LightColors';
import { useNavigate } from 'react-router-dom';
import StickyHeader from '../../components/StickyHeader';

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  color: ${LightColors.textmainlv1};
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-top: 14px;
  color: ${LightColors.textmainlv4};
`;

const BalloonMessage = styled.div`
  width: fit-content;
  background-color: ${LightColors.verfied};
  border-radius: 12px;
  padding: 7px 14px;
  text-align: center;
  position: relative;
  margin-bottom: 18px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: ${LightColors.purewhite};
  animation: float 1.5s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${LightColors.verfied} transparent transparent transparent;
  }
`;

const FanBuddyIntroPage = () => {
  const navigate = useNavigate();
  const onClickStart = () => {
    navigate("/fan-buddy/chat-bot-splash")
  }

  const exit = () => {
    navigate(-1);
  }

  return (
    <>
    <StickyHeader
      title={"덕메찾기"}
      alwaysShowTitle={false}
      showBackButton={false}
      leftContent={
        <CloseIcon width={"20px"} height={"20px"} fill={LightColors.pureblack} onClick={exit}/>
      }
      showTitle={true}
    />
    <AbsoluteCenter style={{padding: "0 24px", width: "100%"}}>
      <Center flexDirection={"column"}>
        <BalloonMessage>
          {"지금은 사전등록 기간이에요"}
        </BalloonMessage>
        <Title>
          {"내게 맞는 덕메찾기"}
        </Title>
        <Description>
          {"같순이랑 덕질해야 더 재밌으니까!"}
        </Description>
      </Center>
      <Center flexDirection={"column"}>
        <img
          width={"172px"}
          height={"172px"}
          src={"/assets/backgrounds/fan-buddy/Maracas.png"}
          alt={""}
          style={{paddingTop: "42px"}}
        />
        <RegularSolidButton
          text={"덕메 카드 만들고 사전 등록 하기"}
          style={{fontSize: "17px", borderRadius: "8px", marginTop: "107px", padding: "16px 0", lineHeight: "20px", fontWeight: 500, width: "100%"}}
          onClicked={onClickStart}
        />
      </Center>
      {/* <AgreementDescription>
        {"시작하기를 누르면 정보저장에 동의합니다"}
      </AgreementDescription> */}
    </AbsoluteCenter>
    </>
  );
};

export default FanBuddyIntroPage;
