import { Image, VStack } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty, isNull } from "lodash";
import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LightColors from '../../constants/LightColors';

const containerStyle = {
  width: "120px",
  height: "120px",
  background: LightColors.surface,
  borderRadius: "12px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
}

const thumbnailImageStyle = {
  aspectRatio: "55/87",
  height: "63px",
  borderRadius: "5px",
  zIndex: 2,
  position: "relative"
}

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 0px!important;
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 3px!important;
`

const PriceName = styled.div`
  color: ${LightColors.textprimary};
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 6px!important;
`

export default function CompactTradingPhotoCard({ tradingPhotoCard }) {
  const navigate = useNavigate();

  if (isEmpty(tradingPhotoCard)) return null;


  const navigateTradingPhotoCardDeatail = () => {
    navigate(`/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}`)
  }

  return (
    <VStack align="center" width="120px" alignItems={"left"} onClick={navigateTradingPhotoCardDeatail}>
      {
        // isEmpty(tradingPhotoCard.pictureUrls) ?
        // <div style={containerStyle}>
        //    <Image
        //     style={thumbnailImageStyle}
        //     src={tradingPhotoCard.photoCard.mediumThumbnailUrl || tradingPhotoCard.photoCard.pictureUrl}
        //     alt={tradingPhotoCard.photoCard.memberName}
        //   />
        // </div>
        // :
        // <div style={containerStyle}>
        //   <Image
        //     style={fullImageStyle}
        //     src={first(tradingPhotoCard.mediumThumbnailUrls) || first(tradingPhotoCard.pictureUrls)}
        //     alt={tradingPhotoCard.photoCard.memberName}
        //   />
        // </div>
        <div style={containerStyle}>
           <Image
            style={thumbnailImageStyle}
            src={tradingPhotoCard.photoCard.mediumThumbnailUrl || tradingPhotoCard.photoCard.pictureUrl}
            alt={tradingPhotoCard.photoCard.memberName}
          />
        </div>
      }
      <PhotoCardName>
        {t("photoCardDetailPage.title", {memberName: tradingPhotoCard.photoCard.memberName, categoryName: tradingPhotoCard.photoCard.categoryName, categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji})}
      </PhotoCardName>

      <EventName>
        {tradingPhotoCard.photoCard.eventName}
      </EventName>

      {tradingPhotoCard.category === "sale" && (
        <PriceName>
          {isNull(tradingPhotoCard.price)
            ? t("tradingPhotoCardPage.offerPriceWithEmoji")
            : tradingPhotoCard.price}
        </PriceName>
      )}
    </VStack>
  );
}
