import { Image, Spinner } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty, throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { formatValue } from "react-currency-input-field";
import { Helmet } from "react-helmet-async";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";

import UpdateTradingPhotoCardsGroup from "../../components/api/mutations/UpdateTradingPhotoCardsGroup.js";
import useGroupTradingPhotoCardsQuery from "../../components/api/queries/useGroupTradingPhotoCardsQuery.js";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import TradeMethodBottomDrawer from "../../components/drawer/TradeMethodBottomDrawer";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.small.svg";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { intlConfig } from "../../utils/CurrencyUtil.js";
import { openNewTab } from "../../utils/UIUtils.js";

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 0px;
  color: ${({ color }) => color || LightColors.textmainlv1};
`

const PriceText = styled.div`
  margin-top: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${LightColors.textprimary};
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(54px + env(safe-area-inset-top));
  padding-bottom: calc(128px + env(safe-area-inset-bottom));
`

const PageRowContainer = styled.div`
  padding: 0px 24px 12px 24px;
  scroll-snap-align: start;
`

const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-left: 8px;
  position: relative;
  padding-top: 12px;
`;

const AddImageButton = styled.div`
  width: 75px;
  height: 75px;
  place-items: center;
  align-content: center;
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
`;

const StickyContainer = styled.div`
  position: sticky;
  background: white;
`;

const HorizontalScrollContainer = styled.div`
  padding-top: 8px;
  display: flex;
  overflow-x: auto; /* 수평 스크롤 가능 */
  scrollbar-width: none; /* Firefox에서 스크롤바 숨기기 */
  -ms-overflow-style: none; /* Internet Explorer 및 Edge에서 스크롤바 숨기기 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera에서 스크롤바 숨기기 */
  }
`;

const PriceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: ${({ index }) => (index > 0 ? "5px" : "0")};
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(245, 245, 245, 1);
  border-radius: 8px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 4px;
  background-color: ${LightColors.primary};
  border-radius: 32px;
  padding: 5px;
  margin-right: -12px;
  margin-top: -8px;
  cursor: pointer;
  display: inline-flex;
`;

const AttachedImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 8px;
  object-fit: cover;
`;

const ImageCount = styled.div`
  font-size: 12px;
  color: ${LightColors.textsublv1};
`

const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv1};
  width: 100%;
  padding: 20px;
  resize: vertical;
  height: 120px;
  outline: none;
`;

const TradeMethodTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: ${LightColors.textmainlv1};
`

const TradeMethodDescription = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${LightColors.textmainlv3};
`

export default function EditGroupSalesTradingPhotoCardsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [tradingPhotoCards, setTradingPhotoCards] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [deletedImageKeys, setDeletedImageKeys] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedTradeMethod, setSelectedTradeMethod] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [description, setDescription] = useState('');

  const user = useUserQuery();
  const tradingPhotoCardsData = useGroupTradingPhotoCardsQuery(params.group_id);

  useEffect(() => {
    setTradingPhotoCards(tradingPhotoCardsData);
    if (tradingPhotoCardsData?.[0]?.pictureUrls) {
      const initialImages = tradingPhotoCardsData[0].pictureUrls.map(url => ({
        url,
        key: url.split('/').pop()
      }));
      setExistingImages(initialImages);
      setDescription(tradingPhotoCardsData[0].description);
      setSelectedTradeMethod(tradingPhotoCardsData[0].tradeMethod);
    }
  }, [tradingPhotoCardsData]);

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if(event.origin !== window.location.origin) return;
      if(isEmpty(event.data?.type)) return;

      if (event.data?.type === "update-trading-photo-cards") {
        const updatedTradingPhotoCards = event.data.params.tradingPhotoCards;
        console.log(updatedTradingPhotoCards)
        const updatedTradingPhotoCardIds = updatedTradingPhotoCards.map((card) => card.tradingPhotoCardId);

        const filtered = tradingPhotoCards.filter((tradingPhotoCard) => updatedTradingPhotoCardIds.includes(tradingPhotoCard.tradingPhotoCardId));
        const result = filtered.map((tradingPhotoCard) => {
          const foundTradingPhotoCard = updatedTradingPhotoCards.find(card => card.tradingPhotoCardId === tradingPhotoCard.tradingPhotoCardId);
          return({
            ...tradingPhotoCard,
            price: foundTradingPhotoCard.price,
            priceNumber: foundTradingPhotoCard.price,
          })
        });
        setTradingPhotoCards(result)
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);
    return () => {
        window.removeEventListener("message", receiveMessage);
    };
  }, [tradingPhotoCards]);

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
  });

  const handleDeleteImage = (type, target) => {
    if (type === 'existing') {
      setDeletedImageKeys(prev => [...prev, target]);
      setExistingImages(prev => prev.filter(img => img.key !== target));
    } else {
      setNewImages(prev => prev.filter((_, idx) => idx !== target));
    }
  };

  const handleAddImage = () => {
    if (existingImages.length + newImages.length < 10) {
      openFilePicker();
    }
  };

  useEffect(() => {
    if (filesContent.length > 0) {
      setNewImages(prev => [...prev, ...filesContent]);
    }
  }, [filesContent]);


  const onDone = async () => {
    if (!isUploading && user?.tradingUserProfile) {
      setIsUploading(true);
      try {
        const payload = {
          deletedImageKeys,
          dataUrls: newImages.map(img => img.content),
          description,
          tradeMethod: selectedTradeMethod,
          groupId: params.group_id,
          tradingPhotoCards: tradingPhotoCards.map((tradingPhotoCard) => {
            return({
              tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
              price: tradingPhotoCard.priceNumber || tradingPhotoCard.price,
            })
          })
        };

        const response = await UpdateTradingPhotoCardsGroup(payload);
        if (response.ok) {
          tradingPhotoCards.forEach((tradingPhotoCard) => {
            if (tradingPhotoCard?.tradingPhotoCardId) {
              queryClient.invalidateQueries(['tradingPhotoCard', tradingPhotoCard.tradingPhotoCardId])
            }
          });
          queryClient.invalidateQueries(['ExploreTradingPhotoCardsV2']);
          queryClient.invalidateQueries(['ExploreTradingPhotoCardsV3']);
          navigate(-1);
        }
      } catch (error) {
        console.error('Error adding trading photo cards:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleTradeMethodSelect = (method) => {
    setSelectedTradeMethod(method);
    setIsDrawerOpen(false);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleEditPrices = () => {
    const tradingPhotoCardIds = tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard.tradingPhotoCardId);
    openNewTab(`/tradings/groups/${params.group_id}/edit-prices?tradingPhotoCardIds=${tradingPhotoCardIds}`)
  }

  const renderImages = () => {
    const totalImages = existingImages.length + newImages.length

    return (
      <ScrollMenu wrapperClassName='padding-left-scroll-container fixed-height-scroll-container'>
        <div>
          <AddImageButton onClick={handleAddImage}>
            <CameraIcon fill="black" />
            <ImageCount>{totalImages}/10</ImageCount>
          </AddImageButton>
        </div>
        {/* 기존 이미지 렌더링 */}
        {existingImages.map((img, index) => (
          <div key={`existing-${index}`}>
            <ImageContainer>
              <AttachedImage src={img.url} alt={`existed image ${index + 1}`} />
              <CloseButton onClick={() => handleDeleteImage('existing', img.key)}>
                <CloseIcon width="16px" height="16px" fill="white" />
              </CloseButton>
            </ImageContainer>
          </div>
        ))}

        {/* 새로운 이미지 렌더링 */}
        {newImages.map((file, index) => (
          <div key={`new-${index}`}>
            <ImageContainer>
              <AttachedImage src={file.content} alt={`new image ${index + 1}`} />
              <CloseButton onClick={() => handleDeleteImage('new', index)}>
                <CloseIcon width="16px" height="16px" fill="white" />
              </CloseButton>
            </ImageContainer>
          </div>
        ))}
      </ScrollMenu>
    )
  }

  return (
    <>
      <Helmet>
        <title>{t("EditGroupSalesTradingPhotoCardsPage.title")}</title>
      </Helmet>
      <StickyHeader title={t("EditGroupSalesTradingPhotoCardsPage.title")} showBackButton={true} alwaysShowTitle={true} />
      <PageWrapper>
        <PageRowContainer>
          <div className="flex justify-between items-center">
          <Title>
            {t("ConfirmNewSalesTradingPhotoCardsPage.subTitleProduct")}
          </Title>
          <Title color={LightColors.primary} onClick={() => handleEditPrices(params.group_id)}>
            {t("EditGroupSalesTradingPhotoCardsPage.subTitleEditPrice")}
          </Title>
          </div>
          {
            !isEmpty(tradingPhotoCards) &&
            <StickyContainer>
              <HorizontalScrollContainer>
                {
                  tradingPhotoCards?.map((tradingPhotoCard, index) => {
                    return (
                      <PriceContainer key={tradingPhotoCard.tradingPhotoCardId} index={index}>
                        <ImageWrapper>
                          <Image
                            src={tradingPhotoCard.photoCard.pictureUrl}
                            width={"40px"}
                            height={"63px"}
                            objectFit={"cover"}
                            loading={"lazy"}
                            borderRadius={"6px"}
                            marginRight={"0px!important"}
                          />
                        </ImageWrapper>
                        <PriceText>
                          {
                            isEmpty(tradingPhotoCard.price) ?
                            t("tradingPhotoCardPage.offerPriceWithEmoji") :
                            formatValue({ value: tradingPhotoCard.priceNumber, intlConfig: intlConfig(user) })
                          }
                        </PriceText>
                      </PriceContainer>
                    )
                  })
                }
              </HorizontalScrollContainer>
            </StickyContainer>
          }
        </PageRowContainer>

        <PageRowContainer style={{paddingBottom: "0px"}}>
          <Title>{t("ConfirmNewSalesTradingPhotoCardsPage.subTitleDescription")}</Title>
        </PageRowContainer>
        <div className="w-full">
          {renderImages()}
        </div>
        <PageRowContainer style={{ paddingTop: "16px" }}>
          <TextArea
            placeholder={t("ConfirmNewSalesTradingPhotoCardsPage.desciprtionPlaceholder")}
            value={description}
            onChange={handleDescriptionChange}
          />
        </PageRowContainer>
        <PageRowContainer className="flex justify-between items-center" style={{ paddingTop: "24px" }} onClick={() => setIsDrawerOpen(true)}>
          <TradeMethodTitle>{t("ConfirmNewSalesTradingPhotoCardsPage.subTitleTradeMethod")}</TradeMethodTitle>
          <div className="flex flex-row gap-[4px]">
            <TradeMethodDescription>
              {t(`TradeMethod.${selectedTradeMethod || 'any'}`)}
            </TradeMethodDescription>
            <ArrowRightIcon fill={LightColors.textsublv1} width={"20px"} height={"20px"} />
          </div>
        </PageRowContainer>
        {
          !isDrawerOpen &&
          <div
            className="bottomNavbar text-center"
            style={{ padding: "0px 24px calc(16px + env(safe-area-inset-bottom)) 24px", zIndex: 9999, background: LightColors.purewhite }}
          >
            {
              isUploading
                ? <Spinner size='md' color="primary.500" />
                : <PrimaryButton paddingY={"16px"} text={t("done")} zIndex={9999} onClick={onDone} />
            }
          </div>
        }
      </PageWrapper>
      {
        isDrawerOpen &&
        <TradeMethodBottomDrawer
          user={user}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onSelectTradeMethod={handleTradeMethodSelect}
        />
      }
    </>
  )
}
