import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function usePhotoCardQuery(photoCardId) {
  const { data: photoCard } = useQuery(
    ["photoCardV1", photoCardId],
    () => queryGet(`/api/v1/photo_cards/${photoCardId}`),{
      select: (data) => data.photoCard,
      enabled: !!photoCardId
    }
  );
  return photoCard;
}
