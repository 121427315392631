import { Box, Center, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, Text, Textarea, VStack, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { validate } from "react-email-validator";
import { useSearchParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import Page from "../components/Page";
import ToastMessageBox from "../components/alerts/ToastMessageBox";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { t } from "i18next";
import LightColors from "../constants/LightColors";

const infoTextStyle = {
  fontSize: "13px",
  fontWeight: 400,
  fontColor: "#666"
}

const inputStyle = {
  fontSize: "16px",
  padding: "17px 16px",
  borderRadius: "12px",
  marginTop: "0px",
  fontWeight: 600,
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const hoverStyle = {
  backgroundColor: "rgba(92, 61, 250, 0.06)",
  border: "0px",
  borderColor: "#FFF"
}

const placeholderStyle = {
  color: "rgba(0, 0, 0, 0.20)"
}

async function reportTradingPhotoCard(params) {
  return fetch(baseUrl() + "/trading_photo_card_reports", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      trading_photo_card_report: params
    }),
  }).then((data) => data.json())
}

export default function TradingReportPage() {
  const [searchParams] = useSearchParams();


  const [code] = useState(parseInt(Math.random() * 10000000000000));
  const [content, setContent] = useState();
  const [email, setEmail] = useState();
  const [isEmailError, setIsEmailError] = useState(false);
  const [checked, setChecked] = useState();
  const [fileName, setFileName] = useState(t("tradingReportPage.reportAttchmentPlaceHolder"));
  const [submitted, setSubmitted] = useState(false)
  const toast = useToast();

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  useEffect(() => {
    if(filesContent && filesContent[0]) {
      setFileName(filesContent[0]?.name)
    }
  }, [filesContent])

  useEffect(() => {
    setIsEmailError(!validate(email))
  }, [email])

  const requiredAllField = () => {
    return content && email && checked;
  }

  const onSubmit = async () => {
    setSubmitted(true);
    if(isEmailError || !requiredAllField()) { return }

    let params = {
      code: code,
      content: content,
      email: email,
      data_url: filesContent[0]?.content,
    }

    if(searchParams.has("chatRoomId")) {
      params["chat_room_id"] = searchParams.get("chatRoomId")
    }

    if(searchParams.has("tradingPhotoCardId")) {
      params["trading_photo_card_id"] = searchParams.get("tradingPhotoCardId")
    }

    if(searchParams.has("matchedTradingPhotoCardId")) {
      params["matched_trading_photo_card_id"] = searchParams.get("matchedTradingPhotoCardId")
    }
    const response = await reportTradingPhotoCard(params);
    if(response.success){
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("tradingReportPage.successToastMessage")}/>
        ),
      })
      window.history.back()
    }else{
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("tradingReportPage.failedToastMessage")}/>
        ),
      })
    }
  }

  return(
    <Page title={t("tradingReportPage.pageName")} showBackButton={true} marginTop={"-24px"}>
      <Flex>
        <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{t("tradingReportPage.title")}</Text>
      </Flex>
      <Center marginTop={"20px"}>
        <Image src={"/assets/icons/report_police.png"} width={"157px"} height={"157px"}/>
      </Center>
      <Box borderRadius={"12px"} border={"2px solid #E7E7E7"}>
        <VStack style={{padding: "16px"}}>
          <Text style={infoTextStyle}>{t("tradingReportPage.descriptionFirstRow")}</Text>
          <Text style={infoTextStyle}>{t("tradingReportPage.descriptionSecondRow")}</Text>
        </VStack>
      </Box>
      <FormControl paddingTop={"20px"}>
        <FormLabel paddingY={"16px"} marginY={0} fontSize={"15px"} fontWeight={700} color={"#222"}>
          {t("tradingReportPage.reportCode")}
        </FormLabel>
        <Input
          disabled
          placeholder={code}
          style={inputStyle}
          _disabled={inputStyle}
          _placeholder={placeholderStyle}
          _hover={hoverStyle}
          focusBorderColor={LightColors.purewhite}
        />
      </FormControl>
      <FormControl paddingTop={"20px"} isInvalid={!content && submitted}>
        <FormLabel paddingY={"16px"} marginY={0} fontSize={"15px"} fontWeight={700} color={"#222"}>
          {t("tradingReportPage.reportDescription")}
        </FormLabel>
        <Textarea
          placeholder={t("tradingReportPage.reportDescriptionPlaceholder")}
          style={inputStyle}
          _placeholder={placeholderStyle}
          _hover={hoverStyle}
          focusBorderColor={LightColors.purewhite}
          onChange={(e) => setContent(e.target.value)}
        />
        { !content && <FormErrorMessage >{t("tradingReportPage.reportDescriptionEmptyErrorMessage")}</FormErrorMessage> }
      </FormControl>
      <FormControl paddingTop={"20px"}>
        <FormLabel paddingY={"16px"} marginY={0} fontSize={"15px"} fontWeight={700} color={"#222"}>
          {t("tradingReportPage.reportAttchment")}
        </FormLabel>
        <Input
          isReadOnly={true}
          placeholder={fileName}
          style={inputStyle}
          _disabled={inputStyle}
          _placeholder={placeholderStyle}
          _hover={hoverStyle}
          focusBorderColor={LightColors.purewhite}
          onClick={() => openFilePicker()}
      />
      </FormControl>
      <FormControl paddingTop={"20px"} isInvalid={isEmailError && !!email && submitted}>
        <FormLabel paddingY={"16px"} marginY={0} fontSize={"15px"} fontWeight={700} color={"#222"}>
          {t("tradingReportPage.reportUserEmail")}
        </FormLabel>
        <Input
          placeholder={"pocapan@gmail.com"}
          style={inputStyle}
          _placeholder={placeholderStyle}
          _hover={hoverStyle}
          focusBorderColor={LightColors.purewhite}
          onChange={(e) => setEmail(e.target.value)}
        />
        {
          isEmailError &&
            <FormErrorMessage>
              {!email ? t("tradingReportPage.reportUserEmailEmptyErrorMessage") : t("tradingReportPage.reportUserEmailFormatErrorMessage")}
            </FormErrorMessage>
        }
      </FormControl>
      <VStack align={"flex-start"} paddingTop={"50px"}>
        <HStack>
          <Checkbox value='agreed' onChange={(e) => setChecked(e.target.checked)}/>
          <Text fontSize={"15px"} fontWeight={400} color={LightColors.pureblack}>{t("tradingReportPage.reportAgreement")}</Text>
        </HStack>
      </VStack>
      <div style={{margin: "17px 0px"}}>
        {
          requiredAllField() ?
          <PrimaryButton text={t("tradingReportPage.reportSubmit")} style={{padding: "16px 24px"}} onClick={() => onSubmit()}/> :
          <SecondaryButton text={t("tradingReportPage.reportSubmit")} style={{padding: "16px 24px"}}/>
        }
      </div>
    </Page>
  )
}
