import React, { useEffect, useRef, useState } from "react";

import { Badge, Box, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, ScaleFade, Spacer, Text, VStack } from '@chakra-ui/react';
import { motion } from "framer-motion";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import styled, { keyframes } from "styled-components";

import LightColors from "../../constants/LightColors";
import { ReactComponent as ChatFillIcon } from "../../icons/chat.fill.svg";
import { ReactComponent as ChatIcon } from "../../icons/chat.svg";
import { ReactComponent as LikeLineIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { ReactComponent as MegaphoneFillIcon } from "../../icons/megaphone.fill.svg";
import { ReactComponent as MegaphoneIcon } from "../../icons/megaphone.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";
import deletePost from "../api/mutations/DeletePost";
import toggleLikedPost from "../api/mutations/LikePost";
import ToggleFollow from "../api/mutations/ToggleFollow";
import AddUserProfileV1BottomSheet from "../bottomSheet/AddUserProfileV1BottomSheet";
import RepostBottomDrawer from "../drawer/RepostBottomDrawer";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import Comment from "./Comment";
import PostImagesBox from "./PostImagesBox";
import QuotedPostPreview from "./QuotedPostPreview";

const TextShine = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

const FandomText = styled.div`
  max-width: 34px;
  padding-top: 4px;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  text-align: center;
  background: linear-gradient(
    to right,
    #7953cd 20%,
    #00affa 30%,
    #0190cd 70%,
    #764ada 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: ${TextShine} 3s infinite linear;
  white-space: pre-wrap;
`

const authorNameStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  lineHeight: "17px",
  fontSize: "14px"
}

const authoredAtStyle = {
  paddingTop: "1px",
  fontWeight: 500,
  color: LightColors.textmainlv4,
  lineHeight: "14px",
  fontSize: "12px",
  paddingLeft: "6px"
}

const titleStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  lineHeight: "20px",
  fontSize: "16px",
  paddingBottom: "3px"
}

const contentStyle = {
  fontWeight: 500,
  color: LightColors.textmainlv2,
  fontSize: "15px",
  lineHeight: "20px",
  paddingBottom: "3px"
}

const CounterText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${(props) => (props.isActive ? (props.isLiked ? LightColors.warning : LightColors.primary) : LightColors.textsublv1)};
`

const eventNameStyle = {
  fontWeight: 500,
  color: LightColors.textmainlv1,
  fontSize: "14px",
  lineHeight: "17px",
  marginBottom: "5px",
  height: "17px"
}

const pocaNameStyle = {
  fontWeight: 400,
  color: LightColors.textmainlv3,
  fontSize: "12px",
  lineHeight: "14px",
  paddingBottom: "5px",
}

const memberNameBadgeStyle = {
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "13px",
  borderRadius: "4px",
  width: "fit-content",
  color: LightColors.onSecondary,
  backgroundColor: LightColors.secondary
}


const RepostBox = styled.div`
  border-width: 1px;
  border-color: ${LightColors.separatorlv2};
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 12px;
`

export default function PostPreview({user, post, navigatePath, disableActions=false}) {
  const focusRef = useRef();
  const queryClient = useQueryClient();
  const [postState, setPostState] = useState();

  const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false);
  const [isOpenRepostDrawer, setIsOpenRepostDrawer] = useState(false);

  useEffect(()=>{ setPostState(post) }, [post]);

  const navigatePostDetails = (isAddComment) => {
    if (isEmpty(user?.baseUserProfile)) {
      return openCreateSocialProfileDrawer();
    }

    let pathName = navigatePath || `/posts/${postState.postId}`;

    if(pathName !== window.location.pathname){
      // navigate(pathName)
      if (isAddComment) {
        pathName = `${pathName}?isAddComment=${isAddComment}`
      }
      openNewTab(pathName);
    }
    // setViewPostId(null);
  }

  const onReportPost = async (postId) => {
    openNewTab(`/feed/report?post_id=${postId}`);
  }

  const onReportUserProfile = async (userProfileId) => {
    openNewTab(`/feed/report?user_profile_id=${userProfileId}`);
  }

  const onRemovePost = async (postId) => {
    if (window.confirm(t("post.confirmDelete"))) {
      const response = await deletePost(postId);
      if (response && response.ok) {
        setPostState(prevPost => ({
          ...prevPost,
          isDeleted: true,
        }))
        queryClient.invalidateQueries("Posts");
      }
    }
  }

  const onLikePost = async (postId) => {
    if (isEmpty(user?.baseUserProfile)) {
      return openCreateSocialProfileDrawer();
    }

    setPostState(prevPost => ({
      ...prevPost,
      isLiked: !prevPost.isLiked,
      likesCount: prevPost.isLiked ? (prevPost.likesCount || 0) - 1 : (prevPost.likesCount || 0) + 1,
    }))

    const response = await toggleLikedPost(postId);
    if (response && response.ok) {
      const responseBody = await response.json();
      if(responseBody && responseBody.post) {
        setPostState(prevPost => ({
          ...prevPost,
          isLiked: responseBody.post.isLiked,
          likesCount: responseBody.post.likesCount,
          commentsCount: responseBody.post.commentsCount,
          repostsCount: responseBody.post.repostsCount,
        }))
      }
    }
  }

  const onClickReposted = async (isMegaphoned) => {
    setPostState(prevPost => ({
      ...prevPost,
      isMegaphoned: isMegaphoned,
      repostsCount: prevPost.isMegaphoned ? (prevPost.repostsCount || 0) - 1 : (prevPost.repostsCount || 0) + 1,
    }))
  }

  const openRepostDrawerDrawer = () => {
    if (isEmpty(user?.baseUserProfile)) {
      return openCreateSocialProfileDrawer();
    }
    setIsOpenRepostDrawer(true);
  }

  const openCreateSocialProfileDrawer = () => {
    setIsOpenProfileDrawer(true);
  }

  const handleFollowUser = async (userProfileId) => {
    const response = await ToggleFollow({
      isFollowed: false,
      currentUserProfileId: user?.baseUserProfile?.userProfileId,
      followUserProfileId: userProfileId,
    });
    if (response && response.ok) {
      setPostState(prevPost => ({
        ...prevPost,
        isFollowing: true
      }));
    }
  };

  if(postState && postState.isDeleted) {
    return (<></>)
  }

  return (postState &&
    <motion.div
      initial={{ opacity: 0, y: -2 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -2 }}
      transition={{ duration: 0.3 }}
    >
    <Flex key={postState.postId} ref={focusRef}>
      <div>
        <ProfileAvatar
          profileImageUrl={postState.anonymous ? "/assets/icons/ghost_profile_icon.png": postState.authorThumbnailUrl}
          widthAndHeight={"34px"}
          onClick={() => postState?.userProfileId && openNewTab(`/feed/user_profile/${postState?.userProfileId}`)}
        />
        {
          postState.fandomName &&
          <FandomText>{t("post.onlyFandom", {fandom: postState.fandomName})}</FandomText>
        }
        <Spacer height={"100%"} onClick={() => postState.postId && navigatePostDetails()}/>
      </div>
      <VStack maxW={"calc(100% - 28px)"} flexGrow={1} spacing={0} align={"left"} paddingLeft={"10px"}>
        <Flex paddingTop={"1px"} paddingBottom={"4px"}>
          <Flex direction={"row"} alignItems={"center"}>
            <Text style={authorNameStyle}>{ellipsis(postState.authorName, 7)}</Text>
            {
              !isEmpty(postState.categoryName) && <Text style={authoredAtStyle}>{`${postState.categoryName} •`}</Text>
            }
            <Text style={authoredAtStyle}>{postState.authoredAt}</Text>

          </Flex>
          <Spacer onClick={() => postState.postId && navigatePostDetails()}/>
          {
            !postState.isMine && !postState.anonymous && !postState.isFollowing && (
              <Text
                marginLeft="8px"
                fontSize="12px"
                lineHeight={"14px"}
                marginTop={"1px"}
                fontWeight="500"
                color={LightColors.primary}
                cursor="pointer"
                onClick={() => handleFollowUser(postState.userProfileId)}
              >
                {`${t("follow")} +`}
              </Text>
            )
          }
        </Flex>
        <div onClick={() => postState.postId && navigatePostDetails()}>
          { postState.title && <Text key={"title"} noOfLines={2} style={titleStyle}>{postState.title}</Text> }
          { postState.content && <Text key={"content"} whiteSpace={"pre-wrap"} noOfLines={5} style={contentStyle}>{postState.content}</Text> }
        </div>
        <div>
          {
            !isEmpty(postState.pictureUrls) &&
              <div style={{paddingTop: "3px", paddingBottom: "3px"}}>
                <PostImagesBox images={postState.pictureUrls} focusRef={focusRef}/>
              </div>
          }
          {
            (postState.quotedPost || postState.megaphonePost) &&
            <RepostBox>
              {
                <QuotedPostPreview post={postState.quotedPost}/>
              }
            </RepostBox>
          }
          {
            isEmpty(postState.pictureUrls) && (postState.photoCard) && (postState.postType !== "comment") &&
            <HStack
              padding={"12px"}
              borderRadius={"12px"}
              marginTop={"3px"}
              marginBottom={"3px"}
              borderColor={LightColors.separatorlv2}
              borderWidth={"1px"}
              onClick={() => openNewTab(`/photo_cards/${postState.photoCard.photoCardId}`)}
            >
              <Image width={"40px"} height={"62px"} borderRadius={"6px"} objectFit={"cover"} src={postState.photoCard.pictureUrl}/>
              <VStack align={"left"} spacing={"0px"}>
                <Text style={eventNameStyle} noOfLines={1}>{postState.photoCard.eventName}</Text>
                <Text style={pocaNameStyle}>{postState.photoCard.pocaname || postState.photoCard.name}</Text>
                <Badge marginTop={"0px"} padding={"4px 8px"} style={memberNameBadgeStyle}>{postState.photoCard.memberName}</Badge>
              </VStack>
            </HStack>
          }
          {
            postState.commentSource &&
            <Box borderWidth={"1px"} borderColor={LightColors.separatorlv2} padding={"12px"} borderRadius={"12px"}>
              <Comment comment={postState.commentSource} isPosted={true}/>
            </Box>
          }
        </div>
        {
          // Disable to action for parent reposted content
          !disableActions &&
          <HStack spacing={"24px"} height={"28px"}>
            <HStack spacing={"4px"} onClick={() => postState.postId && navigatePostDetails(true)}>
              {
                <>
                  {
                    postState.isCommented ?
                    <ChatFillIcon width={"18px"} height={"18px"} fill={LightColors.primary}/> :
                    <ChatIcon width={"18px"} height={"18px"} fill={LightColors.textsublv1}/>
                  }
                  <CounterText isActive={postState.isCommented}>{postState.commentsCount}</CounterText>
                </>
              }
            </HStack>
            <HStack spacing={"4px"} onClick={() => !postState.isDeleted && openRepostDrawerDrawer()}>
              {
                <>
                  {
                    (postState.isMegaphoned || postState.isQuoted) ?
                    <MegaphoneFillIcon width={"18px"} height={"18px"} fill={LightColors.primary}/> :
                    <MegaphoneIcon width={"18px"} height={"18px"} fill={LightColors.textsublv1}/>
                  }
                  <CounterText isActive={(postState.isMegaphoned || postState.isQuoted)}>{postState.repostsCount}</CounterText>
                </>
              }
            </HStack>
            <HStack spacing={"4px"} onClick={() => !postState.isDeleted && onLikePost(postState.postId)} textAlign={"left"}>
              {
                <>
                {
                  postState.isLiked &&
                  <ScaleFade initialScale={0.5} in={postState.isLiked}>
                    <LikeShapeIcon width={"18px"} height={"18px"} fill={LightColors.warning}/>
                  </ScaleFade>
                }
                {
                  !postState.isLiked &&
                  <ScaleFade initialScale={0.9} in={!postState.isLiked}>
                    <LikeLineIcon width={"18px"} height={"18px"} fill={LightColors.textsublv1}/>
                  </ScaleFade>
                }
                <CounterText isActive={postState.isLiked} isLiked={true}>{postState.likesCount}</CounterText>
                </>
              }
            </HStack>
            <Spacer/>
            {
            <Menu>
              <MenuButton
                minWidth={"0px"}
                height={"18px"}
                as={IconButton}
                variant=""
                aria-label='Options'
                paddingX={"0px!important"}
                icon={<MoreIcon fill={LightColors.textsublv2} width={"17px"} height={"17px"} />}
              />
              <MenuList>
                {
                  !postState.isMine &&
                  <MenuItem onClick={() => onReportPost(post.postId)}>
                    {t("post.reportPostMenu")}
                  </MenuItem>
                }
                {
                  (!postState.isMine && !postState.anonymous && post.userProfileId) &&
                  <MenuItem onClick={() => onReportUserProfile(post.userProfileId)}>
                    {t("post.reportUserMenu")}
                  </MenuItem>
                }
                {
                  postState.isMine &&
                  <MenuItem onClick={() => onRemovePost(post.postId)}>
                    {t("post.deletePostMenu")}
                  </MenuItem>
                }
              </MenuList>
            </Menu>
          }
          </HStack>
        }
      </VStack>
      {
        !postState.isDeleted && isOpenRepostDrawer &&
        <RepostBottomDrawer
          focusRef={focusRef}
          post={postState}
          isOpen={isOpenRepostDrawer}
          onClose={() => setIsOpenRepostDrawer(false)}
          onConfirmedRepost={() => onClickReposted(true)}
          onCanceledRepost={() => onClickReposted(false)}
        />
      }
      {
        isOpenProfileDrawer &&
        <AddUserProfileV1BottomSheet
          title={t("createFeedUserProfilePage.title")}
          isShowProfileBottomSheet={isOpenProfileDrawer}
          setIsShowProfileBottomSheet={setIsOpenProfileDrawer}
          category={"base"}
        />
      }
    </Flex>
  </motion.div>
  )
}
