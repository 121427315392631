import { Image } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LightColors from '../../constants/LightColors'

const CardContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border-bottom: 1px solid ${LightColors.surface};
  align-items: center;
  padding: 20px 0px;
`

const InfoSection = styled.div`
  padding-left: 16px;
  padding-right: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PhotoCardName = styled.h3`
  font-size: 15px;
  color: ${LightColors.textmainlv2};
  line-height: 18px;
`

const EventName = styled.p`
  font-size: 14px;
  color: ${LightColors.textmainlv4};
  line-height: 17px;
  font-weight: 400;
`

const StatusBadge = styled.div`
  width: fit-content;
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  background-color: ${props => {
    switch(props.pendingStatus) {
      case 'approved':
        return LightColors.label
      default:
        return LightColors.warningcontainer
    }
  }};
  color: ${props => {
    switch(props.pendingStatus) {
      case 'approved':
        return LightColors.onLabel
      default:
        return LightColors.warning
    }
  }};
`

const DeleteButton = styled.button`
  border-radius: 32px;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 12Px;
  height: fit-content;
  background: ${LightColors.textmainlv2};
  color: ${LightColors.purewhite};
`

export default function ReportedPhotoCardItem({ photoCard, onDelete }) {
  const { t } = useTranslation()

  const pendingStatus = useMemo(() => {
    if(photoCard.visibility && !isEmpty(photoCard.confirmedAt)) {
      return "approved"
    } else if(photoCard.visibility && isEmpty(photoCard.confirmedAt)) {
      return "pending"
    } else {
      return "rejected"
    }
  }, [photoCard.visibility, photoCard.confirmedAt])

  const isDestroyable = (photoCard) => {
    if(photoCard.visibility && !isEmpty(photoCard.confirmedAt)) {
      return false
    } else {
      return true
    }
  }

  return (
    <CardContainer>
      <Image
        key={photoCard.photoCardId}
        src={photoCard.largeThumbnailUrl || photoCard.pictureUrl}
        height={"90px"}
        maxWidth={"57px"}
        objectFit={"cover"}
        aspectRatio={"55/87"}
        borderRadius="8px"
        boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(0, 0, 0, 0.08)"}
      />
      <InfoSection>
        {
          <StatusBadge pendingStatus={pendingStatus}>
            {t(`ReportedPhotoCardsPage.${pendingStatus}`)}
          </StatusBadge>
        }
        <PhotoCardName>
          {t("photoCardDetailPage.title", {memberName: photoCard.memberName, categoryName: photoCard.categoryName, categoryEmoji: photoCard.categoryEmoji})}
        </PhotoCardName>
        <EventName>{photoCard.eventName}</EventName>
      </InfoSection>
      {
        onDelete && isDestroyable(photoCard) &&
        <DeleteButton onClick={() => onDelete(photoCard.photoCardId)}>
          {t("delete")}
        </DeleteButton>
      }
    </CardContainer>
  )
}
