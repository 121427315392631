import { Image, Menu, MenuButton, MenuItem, MenuList, useToast, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";

import StickyHeader from "../../components/StickyHeader";
import ToastMessageBox from "../../components/alerts/ToastMessageBox";
import BumpTradingPhotoCard from "../../components/api/mutations/BumpTradingPhotoCard";
import ToggleWishedTradingPhotoCard from "../../components/api/mutations/ToggleWishedTradingPhotoCard";
import createChatRoom from "../../components/api/mutations/legacy/createChatRoom";
import updateTradingPhotoCards from "../../components/api/mutations/updateTradingPhotoCards";
import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import useReviewStatsQuery from "../../components/api/queries/useReviewStatsQuery";
import useTradingPhotoCardQuery from "../../components/api/queries/useTradingPhotoCardQuery";
import { useTradingPhotoCardsByProfileId } from "../../components/api/queries/useTradingPhotoCards";
import LikeButton from "../../components/buttons/LikeButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullSizeImageViewer from "../../components/chats/ImageViewer";
import VerifiedIcon from "../../components/icons/VerifiedIcon";
import { TagsList } from "../../components/photocard/TagsList";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import CompactTradingPhotoCard from "../../components/tradings/CompactTradingPhotoCard";
import LightColors from "../../constants/LightColors";
import { extractPrice } from "../../utils/TextUtil";
import { setEvent } from "../../utils/ga";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";


const TradingPhotoCardImageWrapper = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
`

const PhotoCardSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 20px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
`

const DotText = styled.div`
  color: ${LightColors.separatorlv2};
  line-height: 21px;
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const PriceText = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: ${LightColors.primary};
`

const DeliveryBadge = styled.div`
  color: ${LightColors.textprimary};
  background: ${LightColors.surface};
  border-bottom: 1px solid ${LightColors.separatorlv1};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

const TagContainer = styled.div`
  padding: 16px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 24px 0px 24px;
  gap: 24px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: ${LightColors.textmainlv1};
`

const ReportLink = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textsublv2};
	text-decoration: underline;
`

const DateTimeText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${LightColors.textsublv1};
`

const SellerTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 16px 24px;
  color: ${LightColors.textmainlv2};
`

const SellerContainer = styled.div`
  padding: 0px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`

const NickName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${LightColors.textmainlv3};
`

const ResponseRate = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${LightColors.textprimary};
`

const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 0 24px 16px 24px;
  gap: 8px;
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For IE and Edge
  &::-webkit-scrollbar { // For Chrome, Safari and Opera
    display: none;
  }
`;

export default function TradingPhotoCardDetailPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const handleHistoryBack = useHandleHistoryBack();
  const currentQueryParams = new URLSearchParams(location.search);

  const [photoCard, setPhotoCard] = useState();
  const [tradingPhotoCard, setTradingPhotoCard] = useState();
  const [selectedPictureUrl, setSelectedPictureUrl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const tradingPhotoCardData = useTradingPhotoCardQuery(params.trading_photo_card_id);
  const photoCardData = usePhotoCardQuery(currentQueryParams.get("photoCardId"));

  const reviewStats = useReviewStatsQuery({userProfileId: tradingPhotoCard?.userProfileId});
  const sellerTradingPhotoCards = useTradingPhotoCardsByProfileId({
    userProfileId: tradingPhotoCard?.userProfileId,
    category: "sale",
  });

  useEffect(() => {
    if (!isEmpty(photoCardData)) {
      setPhotoCard(photoCardData);
    }
  }, [photoCardData]);

  useEffect(() => {
    if (!isEmpty(tradingPhotoCardData)) {
      setTradingPhotoCard(tradingPhotoCardData);
    }
  }, [tradingPhotoCardData]);

  const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => setCurrentIndex(next),
  };

  const toggleWishedState = async (isWished) => {
    if(isEmpty(tradingPhotoCard)) return;

    setEvent({
      category: 'TradingPhotoCard',
      action: 'Toggle Wished TradingPhotoCard',
      label: tradingPhotoCard.tradingPhotoCardId,
    });

    const response = await ToggleWishedTradingPhotoCard(tradingPhotoCard.tradingPhotoCardId, isWished);
    if(response.ok) {
      invalidateQueries(tradingPhotoCard);
      setTradingPhotoCard(prevData => ({
        ...prevData,
        isWished: !isWished,
      }));
    }
  }

  const invalidateQueries = (photoCard) => {
    queryClient.invalidateQueries(["photoCardV1", photoCard.photoCardId]);
    queryClient.invalidateQueries(["photoCardEvents", photoCard.artistId, photoCard.memberId]);
  }

  const postRefreshMessage = () => {
    if (window.opener) {
      window.opener.postMessage({
        type: "refresh-sales-trading-photo-cards",
        params: {tradingPhotoCardId: tradingPhotoCard?.tradingPhotoCardId},
      }, window.location.origin)
    }
  }

  const onRequestBump = async () => {
    const response = await BumpTradingPhotoCard(tradingPhotoCard?.tradingPhotoCardId)
    if (response.ok) {
      const responseBody = await response.json();
      setTradingPhotoCard(prevData => ({
        ...prevData,
        bumpRemainingTime: responseBody.tradingPhotoCard.bumpRemainingTime,
      }));

      postRefreshMessage();

      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("TradingPhotoCardDetailPage.toastBumped")}/>
        ),
      });
    }
  }

  const onEditTradingPhotoCard = async () => {
    if(isEmpty(tradingPhotoCard)) return;

    navigate(`/tradings/groups/${tradingPhotoCard.groupId}/edit`);
  }

  const onDeleteTradingPhotoCard = async () => {
    if(isEmpty(tradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: extractPrice(tradingPhotoCard.price),
      status: "sold_out",
    });

    postRefreshMessage();

    if(response.ok) {
      setTradingPhotoCard(prevData => ({
        ...prevData,
        status: "sold_out",
      }));
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("TradingPhotoCardDetailPage.toastSoldout")}/>
        ),
      });
    }
  }

  const navigateSellerProfile = () => {
    navigate(`/trading/user_profile/${tradingPhotoCard?.userProfileId}`)
  }

  const navigateChatRoom = async () => {
    const response = await createChatRoom(tradingPhotoCard.artistId, tradingPhotoCard.tradingPhotoCardId);
    if (response.success) {
      navigate(`/artists/${tradingPhotoCard.artistId}/chats/${response.chatRoom.chatRoomId}`)
    }
  }

  const navigateReport = () => {
    navigate(`/trading_reports?tradingPhotoCardId=${tradingPhotoCard.tradingPhotoCardId}`)
  }

  const ImageUrls = useMemo(() => {
    if (isEmpty(tradingPhotoCard?.pictureUrls)) {
      return [photoCard?.pictureUrl]
    } else {
      return [...tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]
    }
  }, [tradingPhotoCard?.pictureUrls, tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]);

  if(isEmpty(photoCard) || isEmpty(tradingPhotoCard)) return <></>

  return (
    <div className="pb-24">
      <StickyHeader
        transparent={true}
        showBackButton={true}
        rightContent={
          tradingPhotoCard?.isMine &&
          <MeatBallMenus
            onEditItem={onEditTradingPhotoCard}
            onDeleteItem={onDeleteTradingPhotoCard}
          />
        }
        onClickBackButton={() => {
          postRefreshMessage();
          handleHistoryBack();
        }}
      />
      <TradingPhotoCardImageWrapper>
        {
          tradingPhotoCard &&
            <div className="relative">
              <Slider {...SLIDER_SETTINGS}>
                {ImageUrls?.map((pictureUrl, index) => (
                  <motion.div
                    key={pictureUrl}
                    initial={{ opacity: 0, y: -1 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div key={pictureUrl} className="w-full">
                      {index === ImageUrls.length - 1 ? (
                        <div className="w-full h-[375px] flex items-center justify-center" style={{background: LightColors.surface}}>
                          <Image
                            width={"122px"}
                            height={"182px"}
                            objectFit={"contain"}
                            src={pictureUrl}
                            onClick={() => setSelectedPictureUrl(pictureUrl)}
                            boxShadow={"0px 0px 15px 0px #0000001A"}
                          />
                        </div>
                      ) : (
                        <Image
                          width={"100%"}
                          height={"375px"}
                          objectFit={"cover"}
                          src={pictureUrl}
                          onClick={() => setSelectedPictureUrl(pictureUrl)}
                        />
                      )}
                    </div>
                  </motion.div>
                ))}
              </Slider>
              <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                {ImageUrls?.map((_, index) => (
                  <div
                    key={index}
                    className={"w-2 h-2 rounded-full"}
                    style={{ background: index === currentIndex ? LightColors.primary : LightColors.placeholder }}
                  />
                ))}
              </div>
            </div>
        }
      </TradingPhotoCardImageWrapper>
      <PhotoCardSection>
        {
          isEmpty(photoCard) ? (
            <div className="w-full">
              <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
            </div>
          ) : (
            <VStack align={"start"} spacing={"16px"} width={"100%"}>
            <Row>
              <PhotoCardName>
                {t("photoCardDetailPage.title", {memberName: photoCard.memberName, categoryName: photoCard.categoryName, categoryEmoji: photoCard.categoryEmoji})}
              </PhotoCardName>
              <DotText>{"•"}</DotText>
              <EventName>
                {photoCard.eventName}
              </EventName>
            </Row>
            <div className="flex justify-between w-full mt-0">
              <PriceText>
                {isEmpty(tradingPhotoCard.price) ? t("tradingPhotoCardPage.offerPriceWithEmoji") : tradingPhotoCard.price}
              </PriceText>
              <div className="flex items-center gap-2">
                {
                  tradingPhotoCard.tradeMethod &&
                  <DeliveryBadge px={"8px"} py={"4px"}>
                    {t(`TradeMethod.${tradingPhotoCard.tradeMethod}`)}
                  </DeliveryBadge>
                }
              </div>
            </div>
            </VStack>
          )
        }
      </PhotoCardSection>
      {
        (photoCard && !isEmpty(photoCard.distributors) && !isEmpty(photoCard.tags)) &&
        <TagContainer>
          <TagsList photoCard={photoCard} size={"md"}/>
        </TagContainer>
      }
      <DescriptionWrapper>
        <Description>{tradingPhotoCard.description}</Description>
        <ReportLink onClick={navigateReport}>{t("TradingPhotoCardDetailPage.reportTrading")}</ReportLink>
        <div className="flex flex-row ml-auto" style={{paddingBottom: "16px", gap: "4px"}}>
          <DateTimeText>{tradingPhotoCard.requestedAt}</DateTimeText>
          {
            tradingPhotoCard.bumpedAtText &&
            <>
              <DateTimeText>{"•"}</DateTimeText>
              <DateTimeText>{t("TradingPhotoCardDetailPage.bumpedAt", {bumpedAt: tradingPhotoCard.bumpedAtText})}</DateTimeText>
            </>
          }
        </div>
      </DescriptionWrapper>
      <SellerTitle>{t("TradingPhotoCardDetailPage.sellerTitle")}</SellerTitle>

      <SellerContainer onClick={navigateSellerProfile}>
        <ProfileAvatar profileImageUrl={tradingPhotoCard?.userProfile?.pictureUrl} widthAndHeight={"43px"} svgWidthAndHeight={"32px"} />
        <VStack align="start" gap={"5px"} alignSelf={"center"}>
          <div className="flex flex-row items-center gap=[3px]">
            <NickName>{tradingPhotoCard?.userProfile?.nickname}</NickName>
            {
              tradingPhotoCard?.userProfile?.identified && <VerifiedIcon width={"13px"} height={"13px"} />
            }
            {
              tradingPhotoCard?.userProfile?.responseText &&
              <>
                <DotText style={{lineHeight: "17px"}}>{"•"}</DotText>
                <ResponseRate>{`${tradingPhotoCard?.userProfile?.responseText}`}</ResponseRate>
              </>
            }
          </div>
          {
            reviewStats &&
            ScoreTitle({
              reviewsCount: reviewStats.reviewsCount,
              positiveFeedbackOptions: reviewStats.positiveFeedbackOptions
            })
          }
        </VStack>
      </SellerContainer>
      {
        sellerTradingPhotoCards && <TradingPhotoCardsList tradingPhotoCards={sellerTradingPhotoCards}/>
      }
      <div
        className="bottomNavbar flex items-center gap-4 w-full"
        style={{ padding: "12px 24px calc(12px + env(safe-area-inset-bottom)) 24px", zIndex: 9999, background: LightColors.purewhite }}
      >
        {
          (tradingPhotoCard.status === "sold_out" || tradingPhotoCard.status === "canceled") ?
          <PrimaryButton
            width={"100%"}
            py={"16px"}
            disabled={true}
            text={t("TradingPhotoCardDetailPage.buttonSoldout")}
            borderRadius={"8px"}
            style={{
              fontSize: "17px",
              fontWeight: 600,
              lineHeight: "20px"
            }}
          /> : (
          <>
            <LikeButton
              width="52px"
              height="52px"
              onClick={() => toggleWishedState(tradingPhotoCard.isWished)}
              selected={tradingPhotoCard.isWished}
            />
            {
              tradingPhotoCard?.isMine ? (
                <PrimaryButton
                  width={"100%"}
                  py={"16px"}
                  borderRadius={"8px"}
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                  disabled={!isEmpty(tradingPhotoCard.bumpRemainingTime)}
                  text={tradingPhotoCard.bumpRemainingTime ? tradingPhotoCard.bumpRemainingTime : t("TradingPhotoCardDetailPage.bump")}
                  onClick={!tradingPhotoCard.bumpRemainingTime && onRequestBump}
                />
              ) : (
                <PrimaryButton
                  width={"100%"}
                  py={"16px"}
                  borderRadius={"8px"}
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    lineHeight: "20px"
                  }}
                  text={t("sendMessage")}
                  onClick={() => navigateChatRoom()}
                />
              )
            }
          </>)
        }
      </div>
      {
        selectedPictureUrl && <FullSizeImageViewer imageUrl={selectedPictureUrl} onClosed={() => setSelectedPictureUrl(null)}/>
      }
    </div>
  )
}


const StyledScoreTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textsublv1};
  margin-top: 0px!important;
`;

const ColorText = styled.span`
  color: ${(props) => props.reviewColor};
  font-weight: 600;
`;

const ReviewScoreColor = (score) => {
  return score <= 30 ? "#FF4C41" : (score <= 60 ? "#FAD722" : "#2DDD28")
}

const ColoreText = (score) => {
  return score <= 30 ? t("ReviewColorText.red") : (score <= 60 ? t("ReviewColorText.yellow") : t("ReviewColorText.green"))
}

const MannerSignalImage = (score) => {
  if (score <= 30) {
    return "/assets/icons/manner-signals/manner.signal.red.png";
  } else if (score <= 60) {
    return "/assets/icons/manner-signals/manner.signal.yellow.png";
  } else {
    return "/assets/icons/manner-signals/manner.signal.green.png";
  }
};


const ScoreTitle = ({ score }) => {
  const reviewColor = ReviewScoreColor(score);
  const colorText = ColoreText(score);

  return (
    <StyledScoreTitle>
      <div className="flex flex-row">
        <div>{t("TradingUserProfilePage.mannerSignal")}</div>
        <Image width={"15px"} height={"15px"} marginLeft={"4px"} marginRight={"2px"} src={MannerSignalImage(score)}/>
        <Trans
          i18nKey="TradingUserProfilePage.reviewColorText"
          values={{ color: colorText }}
          components={{
            color: <ColorText reviewColor={reviewColor} />
          }}
        />
      </div>
    </StyledScoreTitle>
  );
};


const TradingPhotoCardsList = ({tradingPhotoCards}) => {
  return (
    <>
      {
        isUndefined(tradingPhotoCards) ?
          <GridSkeletons gridSize={3} skeletonSize={9} skeletonHeight={'109px'} gap={"6px"}/> :
          <HorizontalScrollContainer>
            {
              tradingPhotoCards?.map((tradingPhotoCard) => (
                <div key={tradingPhotoCard.tradingPhotoCardId}>
                  <CompactTradingPhotoCard
                    tradingPhotoCard={tradingPhotoCard}
                    hiddenNickname={true}
                    hiddenChatStat={true}
                    showEventName={true}
                  />
                </div>
              ))
            }
          </HorizontalScrollContainer>
      }
    </>
  )
}

function MeatBallMenus({onEditItem, onDeleteItem}) {
  return(
    <Menu autoSelect={false} zIndex={9999}>
      <MenuButton color={LightColors.pureblack}>
        <div className={"text-lg font-semibold"}>{"⋯"}</div>
      </MenuButton>
      <MenuList color={LightColors.pureblack}>
        <MenuItem onClick={onEditItem}>{t("TradingPhotoCardDetailPage.edit")}</MenuItem>
        <MenuItem onClick={onDeleteItem}>{t("TradingPhotoCardDetailPage.delete")}</MenuItem>
      </MenuList>
    </Menu>
  )
}
