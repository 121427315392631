import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function ToggleWishedTradingPhotoCard(tradingPhotoCardId, wished) {
  return fetch(baseUrl() + `/trading_photo_cards/${tradingPhotoCardId}/${wished ? "unwished" : "wished"}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  });
}
