import styled from "styled-components";
import { ReactComponent as LikeLineIcon } from "../../icons/like.line.svg";
import LightColors from "../../constants/LightColors";

const StyledButton = styled.button`
  min-width: 52px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  background-color: ${({ $selected }) =>
    $selected ? LightColors.primary : LightColors.secondary};
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, fill 0.2s;

  svg {
    fill: ${({ $selected }) =>
      $selected ? LightColors.purewhite : LightColors.onSecondary};
  }
`;

const LikeButton = ({ onClick, selected }) => {
  return (
    <StyledButton
      onClick={onClick}
      $selected={selected}
    >
      <LikeLineIcon width="24px" height="24px"  />
    </StyledButton>
  );
};

export default LikeButton;
